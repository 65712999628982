import React from 'react';
import {Link} from "react-router-dom";

const ServiceCard = (props) => {
    let item = props.elements
    return (
        <div className="col margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn">
            <div
                className="h-100 feature-box text-start feature-box-show-hover box-shadow-small-hover feature-box-bg-white-hover border-all border-color-black-transparent overflow-hidden">
                <div
                    className="feature-box-move-bottom-top padding-3-rem-tb padding-4-rem-lr md-padding-2-rem-tb md-padding-2-half-rem-lr sm-padding-3-rem-tb sm-padding-4-half-rem-lr">
                    <img
                        className="rounded-3 w-100px h-100px padding-10px-all d-inline-block margin-20px-bottom letter-spacing-minus-2px"
                        src={item.better_featured_image !== null ? item.better_featured_image.source_url : `https://via.placeholder.com/300x200&text=${item.title.rendered}`}
                        alt=""/>

                    <div className="feature-box-content last-paragraph-no-margin">
                                    <span
                                        className="text-extra-dark-gray text-extra-medium font-weight-600 text-uppercase d-block margin-10px-bottom alt-font">{item.title.rendered}</span>
                        <div className="text-medium-gray" dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                    </div>
                    <div className="move-bottom-top margin-15px-top">

                    </div>
                </div>
            </div>
        </div>












    );
};

export default ServiceCard;