import React, {useContext, useEffect, useState} from "react";
import {EventDataContext} from "../../context/EventDataContext";
import {apiGet} from "../Tools/apiConfig";
import {FaqDataContext} from "../../context/FaqDataContext";
import {Link} from "react-router-dom";

const Faq = () => {

    const {faqItems, setFaqItems} = useContext(FaqDataContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const getFaqItems = async () => {

        const baseURL = '/wp-json/wp/v2/faq?_fields[]=title&_fields[]=content&_fields[]=id&_fields[]=show_on_home_page';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setFaqItems(response.data);
                setIsLoaded(true);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (faqItems.length > 0) {
            setIsLoaded(true)
        } else {
            getFaqItems();
        }
    }, [faqItems])

    return (
        <div>
            <section className="bg-seashell py-lg-0">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div
                            className="col-12 col-xl-5 col-lg-6 col-md-10 padding-6-rem-tb lg-padding-5-rem-tb lg-padding-2-half-rem-left md-padding-15px-right md-no-padding-tb md-margin-5-rem-bottom me-md-auto">
                            <h5 className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-4-half-rem-bottom md-margin-4-rem-bottom lg-margin-2-half-rem-bottom md-w-100 wow animate__fadeIn">
                                ما راه حل هایی برای رشد کسب و کار شما ارائه می دهیم
                            </h5>
                            <div className="panel-group accordion-event accordion-style-03" id="accordion3"
                                 data-active-icon="icon-feather-chevron-down"
                                 data-inactive-icon="icon-feather-chevron-left">
                                {
                                    faqItems.filter(x => x.show_on_home_page === "1").map((item, index) => {
                                        return (
                                            <div key={index}
                                                 className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <div className="panel-heading active-accordion">
                                                    <a className="accordion-toggle collapsed" data-bs-toggle="collapse"
                                                       data-bs-parent="#accordion3" href={'#collapse' + index}
                                                       aria-expanded="false">
                                                        <div className="panel-title">
                                                <span
                                                    className="alt-font text-extra-dark-gray d-inline-block font-weight-500">{item.title.rendered}</span>
                                                            <i className="indicator feather icon-feather-chevron-down text-extra-dark-gray icon-extra-small"/>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id={'collapse' + index}
                                                     className={"panel-collapse collapse" + (index === 0 ? ' show' : '')}
                                                     data-bs-parent="#accordion3">
                                                    <div className="panel-body"
                                                         dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <p className="m-0 wow animate__fadeIn" data-wow-delay="0.8s">
                                نیاز به مشورت دارید؟&nbsp;
                                <Link to="/contact"
                                      className="text-extra-dark-gray text-tussock-hover font-weight-500 text-decoration-line-bottom">
                                    با ما تماس بگیرید
                                </Link>
                            </p>
                        </div>
                        <div className="col-12 col-lg-6  col-md-10"
                             style={{visibility: "visible", animationName: "fadeInLeft"}}>
                            <div className="outside-box-left position-relative">
                                <img src="/assets/images/home-consulting-provide-solution.jpg"
                                     className="overflow-hidden"
                                     alt="faq"
                                     data-no-retina=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Faq;