import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {SliderDataContext} from "../../context/SliderDataContext";
import Skeleton from 'react-loading-skeleton';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard, Pagination} from "swiper";
import {apiGet} from "../Tools/apiConfig";

const Slider = () => {
    const {sliderItems, setSliderItems} = useContext(SliderDataContext)
    const [isLoaded, setIsLoaded] = useState(false);
    // const navigationPrevRef = useRef(null)
    // const navigationNextRef = useRef(null)

    const getSliderItems = async () => {

        let fields = "_fields[]=title&_fields[]=content&_fields[]=better_featured_image&_fields[]=slug&_fields[]=featured_media&_fields[]=button_text&_fields[]=button_link";
        const baseURL = "/wp-json/wp/v2/slider?" + fields;
        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setSliderItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (sliderItems.length > 0) {
            setIsLoaded(true)
        } else {
            getSliderItems().then(r => {
                setIsLoaded(true)
            })
        }
    }, [sliderItems])

    if (isLoaded) {
        return (
            <section className="p-0 main-slider margin-3-rem-bottom">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay, Keyboard]}
                    className={'swiper-container white-move mobileoff-fullscreen-top-space md-h-600px sm-h-500px'}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    keyboard={{enabled: true, onlyInViewport: true}}
                    navigation={{
                        prevEl: ".swiper-button-previous-nav",
                        nextEl: ".swiper-button-next-nav",
                    }}
                    pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                    }}
                >
                    <div className="swiper-wrapper">
                        {
                            (isLoaded) ?

                                sliderItems.map((item, i) =>
                                    <SwiperSlide className="cover-background"
                                                 style={{"backgroundImage": "url('" + item.better_featured_image.source_url + "')"}} key={i}>
                                        <div className="overlay-bg bg-gradient-dark-slate-blue"/>
                                        <div className="container h-100">
                                            <div className="row h-100">
                                                <div
                                                    className="col-12 col-xl-8 col-lg-7 col-sm-8 h-75 d-flex justify-content-center flex-column position-relative">

                                                    {
                                                        (item.content.rendered.length>0) ?
                                                            <div
                                                                className="alt-font text-extra-large text-white opacity-7 font-weight-300"
                                                                dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                                            : ''
                                                    }

                                                    <h4 className="alt-font text-shadow-double-large font-weight-600 text-white margin-55px-bottom w-90 md-w-100 md-no-text-shadow xs-margin-35px-bottom">
                                                        {item.title.rendered}
                                                    </h4>
                                                    <div className="d-inline-block">
                                                        <Link to={item.button_link} rel="noreferrer"
                                                           className="btn btn-fancy btn-medium btn-primary margin-30px-right xs-margin-15px-bottom">{item.button_text}</Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ) :
                                <SwiperSlide className="cover-background">
                                    <Skeleton height={"100%"}/>
                                </SwiperSlide>

                        }

                    </div>

                    <div className="swiper-pagination swiper-light-pagination"/>

                </Swiper>
            </section>
        )
    } else {
        return (
            <section className="home-slides owl-theme">
                {
                    <Skeleton height={"100vh"}/>
                }
            </section>
        )
    }
};


export default Slider;