import React from 'react';
import {Link} from "react-router-dom";



const About = () => {
    return (
        <>
            <section className="pt-1 pb-1 overflow-visible cover-background wow animate__fadeIn">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-lg-6  col-md-10 wow animate__fadeIn" data-wow-delay="0.4s">
                            <div className="alt-font font-weight-500 margin-30px-bottom d-flex">
                                <span
                                    className="flex-shrink-0 w-30px h-1px bg-primary opacity-7 align-self-center margin-20px-right"/>
                                <div className="flex-grow-1"><span className="text-primary text-uppercase">درباره شتابدهنده اینترنت اشیا</span>
                                </div>
                            </div>
                            <h5 className="alt-font text-uppercase text-extra-dark-gray font-weight-700 w-85 margin-30px-bottom xl-w-100">تاریخچه
                                تاسیس آکادمی اینترنت اشیاء</h5>
                            <p className="w-100 xl-w-100 text-justify"> آکادمی اینترنت اشیاء با هدف فرهنگ‌سازی دانش
                                اینترنت
                                اشیاء و ایجاد بستر تخصصی آموزشی در زمینه اینترنت اشیاء فعالیت خود را از ابتدای سال ۱۳۹۴
                                آغاز
                                کرده است. با توجه به اهمیت فراوان اینترنت اشیاء و فراگیر شدن آن در دنیا، نیاز به بستری
                                تخصصی
                                جهت آموزش و توانمندسازی در حوزه‌ی اینترنت اشیاء احساس می‌شود. آکادمی اینترنت اشیاء با
                                بهره‌گیری از توان متخصصین و نخبگان جوان کشور در راستای ایجاد این بستر تخصصی آموزشی گام
                                برداشت. با بررسی متدهای نوین آموزشی در کشورهای پیشرفته، برای اولین بار کارگاه‌هایی
                                متفاوت و
                                کوتاه مدت را طراحی و اجرا کرد. هدف از این کارگاه‌های کوتاه مدت انتقال تمامی مطالب
                                کاربردی در
                                کمترین زمان ممکن و افزایش توانایی اتکا به خود جهت ادامه‌ی راه و یادگیری بیشتر است.
                                آکادمی
                                اینترنت اشیاء همچنین با رصد تمامی فعالیت‌های شرکت‌های بزرگ در حوزه‌ی اینترنت اشیاء،
                                نیازهای
                                فنی و توانمندی‌های مورد نیاز جهت فعالیت در این حوزه در دنیا و همچنین ایران را بررسی کرد
                                و
                                سرفصل‌هایی در راستای ایجاد و ارتقاء این توانمندی‌های فنی جهت آموزش در کارگاه‌ها طراحی
                                کرد.
                                تمامی تکنولوژی‌ها و ابزارهای مورد استفاده در تمامی شرکت‌های بزرگ بررسی شد و پرکاربردترین
                                و
                                بهترین آن‌ها جهت آموزش در کارگاه انتخاب شدند. اکنون آکادمی اینترنت اشیاء با داشتن
                                تجربه‌ی
                                کار عملی در حوزه‌ی اینترنت اشیاء و تجربه‌ی برگزاری تعداد زیادی کارگاه‌های کوتاه مدت از
                                سال
                                ۱۳۹۴، پیشرو آموزش اینترنت اشیاء در کشور است.</p>

                        </div>
                        <div
                            className="col-12 col-lg-6 col-md-10 position-relative margin-twenty-five-top lg-margin-30px-top md-margin-9-rem-bottom sm-margin-9-rem-bottom wow animate__fadeIn"
                            data-wow-delay="0.2s">
                            <div
                                className="position-absolute left-15px bottom-0px w-75 lg-bottom-minus-50px xs-bottom-minus-25px"
                                data-parallax-layout-ratio="1.1" style={{bottom: "-16.3222px"}}>
                                <img src="/assets/images/images-aboutus.jpg" alt="" data-no-retina=""/>
                            </div>
                            <div className="w-40 overflow-hidden position-relative md-w-70">
                                <img src="/assets/images/home-interior-design-about-img02.jpg" alt=""
                                     data-no-retina=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

          
        </>
    )
        ;
};

export default About;