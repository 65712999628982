import React from 'react';
import StartupForm from "../../components/Consulting/StartupForm";
import InvestorsForm from "../../components/Consulting/InvestorsForm";
import RegulatoryForm from "../../components/Consulting/RegulatoryForm";
import {Helmet} from "react-helmet";

const ConsultingList = () => {
    return (
        <>
            <Helmet>

                    <title>Bita Group - ConsultingList</title>
                </Helmet>
                <section className="big-section bg-light-gray wow animate__fadeIn">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center margin-six-bottom">
                                <h6 className="alt-font text-extra-dark-gray font-weight-500">فرم مشاوره</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 tab-style-02">
                                <ul className="nav nav-tabs justify-content-center text-center alt-font margin-6-rem-bottom sm-margin-3-rem-bottom">
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#Startup">
                                            <i className="feather icon-feather-briefcase icon-small"/>تیم‌های استارت اپی
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#Investors">
                                            <i className="feather icon-feather-crosshair icon-small"/>سرمایه گذاران
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#Regulatory">
                                            <i className="feather icon-feather-maximize icon-small"/>مجموعه نظارتی
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div id="Startup" className="tab-pane fade">
                                        <StartupForm/>
                                    </div>
                                    <div id="Investors" className="tab-pane fade">
                                        <InvestorsForm/>
                                    </div>
                                    <div id="Regulatory" className="tab-pane fade">
                                        <RegulatoryForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>

    );
};

export default ConsultingList;