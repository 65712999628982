import {Link} from "react-router-dom";
import React from "react";

const BlogCard = (props) => {
    let item = props.elements
    return (

        <div className="grid-item wow animate__fadeIn" data-wow-delay="0.2s">
            <div className="blog-post margin-20px-bottom">
                <div
                    className="blog-post-image margin-15px-bottom md-margin-35px-bottom xs-margin-25px-bottom">
                    <Link to={"/blog/" + item.slug}>
                        <img
                            src={item.better_featured_image !== null ? item.better_featured_image.source_url : `https://via.placeholder.com/300x200&text=${item.title.rendered}`}
                            alt={item.title.rendered}/>
                    </Link>
                </div>
                <div
                    className="post-details margin-15px-bottom md-margin-10px-bottom xs-no-margin-bottom">
                    <Link to={"/blog/" + item.slug}
                          className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-block margin-10px-bottom xs-margin-10px-bottom blogtextheight">
                        {(item.title.rendered.length > 60) ? item.title.rendered.substring(0, 57) + '...' : item.title.rendered}
                    </Link>
                    <p className="w-95 mb-2">
                        {
                            new Date(item.date).toLocaleDateString('fa-IR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })
                        }
                    </p>
                    <span
                        className="separator bg-primary"/>
                    <Link to={"/blog/" + item.slug}
                          className="alt-font font-weight-500 text-extra-small text-uppercase text-primary">
                        مشاهده بیشتر
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;