import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';

import {BlogDataContext} from "../../context/BlogDataContext";

import BlogCategory from '../../components/Blog/BlogCategory';
import BlogTags from '../../components/Blog/BlogTags';

import SharePage from "../../components/Tools/SharePage";
import LatestBlog from "../../components/Blog/LatestBlog";
// import Comment from "../../components/Blog/Comment";
// import CommentSkeleton from "../../components/Blog/CommentSkeleton";

import 'react-loading-skeleton/dist/skeleton.css';
import {apiGet} from "../../components/Tools/apiConfig";


const BlogDetail = () => {
    const {blogItems, setBlogItems} = useContext(BlogDataContext);
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [date, setDate] = useState('');
    const [categories, setCategories] = useState('');
    const [tags, setTags] = useState([]);
    // const [comments, setComments] = useState([]);
    const [isLoaded, setLoading] = useState(false);
    const {slug} = useParams()

    const getData = async () => {
        if (blogItems.length > 0) {
            let item = await [].concat(blogItems).find(element => decodeURIComponent(element.slug) === slug);
            if (item) {
                setId(item.id);
                setTitle(item.title.rendered);
                setContent(item.content.rendered);
                setDate(item.date)
                setImage(item.better_featured_image !== null ? item.better_featured_image.source_url : `https://via.placeholder.com/900x600&text=${item.title.rendered}`);

                setCategories(item._embedded["wp:term"][0]);
                setTags(item._embedded["wp:term"][1]);
                setLoading(true);
            }
        }
    };
    const getBlogItems = async () => {

        const baseURL = '?rest_route=/wp/v2/posts&_embed&per_page=100';

        try {
            const fetchData = apiGet(baseURL).then((response) => {
                setBlogItems(response.data);
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (blogItems.length > 0) {
            getData()
        } else {
            getBlogItems();
        }
    }, [blogItems, slug])


    return (
        <>
            <Helmet>
                <title>Mena IoT Alliance - {title}</title>
                <meta property="og:title" content={title}/>
                {/*<meta property="og:image"*/}
                {/*      content={states.items.seo.og_image[0].url}/>*/}
                {/*<meta name="robots" content="noindex" />*/}
            </Helmet>
            <section className="blog-right-side-bar">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 right-sidebar md-margin-60px-bottom sm-margin-40px-bottom">
                            <div className="row">
                                <div
                                    className="col-12 blog-details-text last-paragraph-no-margin margin-6-rem-bottom">
                                    <ul className="list-unstyled margin-2-rem-bottom">
                                        <li className="d-inline-block align-middle margin-25px-left"><i
                                            className="feather icon-feather-calendar text-fast-blue margin-10px-left"/>
                                            <span>
                                                    {
                                                        (isLoaded) ?

                                                            new Date(date).toLocaleDateString('fa-IR', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric'
                                                            }) :
                                                            <Skeleton width={'100px'}/>
                                                    }

                                                </span>
                                        </li>
                                        <li className="d-inline-block align-middle margin-25px-left"><i
                                            className="feather icon-feather-folder text-fast-blue margin-10px-left"/>
                                            {
                                                (isLoaded) ?
                                                    categories.map((cats, i) =>
                                                        <Link to={"/blog/category/" + cats.slug}
                                                              key={i}>{cats.name}</Link>
                                                    ) :
                                                    <Skeleton width={'100px'}/>
                                            }
                                        </li>

                                    </ul>
                                    <h5 className="alt-font font-weight-500 text-extra-dark-gray margin-4-half-rem-bottom">

                                        {(isLoaded) ?
                                            title :
                                            <Skeleton width={'80%'}/>
                                        }
                                    </h5>
                                    {
                                        (isLoaded) ?
                                            <img
                                                src={image}
                                                alt=""
                                                className="w-100 border-radius-6px margin-4-half-rem-bottom"/> :
                                            <Skeleton width={'100%'} height={'400px'}/>
                                    }

                                    {
                                        (isLoaded) ?
                                            <div dangerouslySetInnerHTML={{__html: content}}/> :
                                            <Skeleton count={10}/>
                                    }


                                </div>
                                <div
                                    className="col-12 d-flex flex-wrap align-items-center padding-15px-tb mx-auto margin-20px-bottom wow animate__fadeIn">
                                    <div
                                        className="col-12 col-md-9 text-center text-md-start sm-margin-10px-bottom px-0">
                                        <div className="tag-cloud">
                                            {
                                                tags.map((tag, i) =>
                                                    <Link to={"/blog/tag/" + tag.slug} key={i}>{tag.name}</Link>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>

                                <SharePage/>
                            </div>
                        </div>
                        <aside
                            className="col-12 col-xl-3 offset-xl-1 col-lg-4 col-md-7 blog-sidebar lg-padding-4-rem-left md-padding-15px-left">
                            <div className="margin-5-rem-bottom xs-margin-35px-bottom wow animate__fadeIn">
                                <span
                                    className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-35px-bottom">دسته بندی ها</span>
                                <BlogCategory/>
                            </div>
                            <div
                                className="margin-5-rem-bottom xs-margin-35px-bottom md-padding-3-rem-top wow animate__fadeIn">
                                <span
                                    className="alt-font font-weight-500 text-large text-extra-dark-gray d-block margin-35px-bottom">تگ ها</span>
                                <div className="tag-cloud">
                                    <BlogTags/>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>

            <section className="bg-light-gray">
                <div className="container">
                    <div className="row justify-content-center">
                        <div
                            className="col-12 col-lg-5 col-md-6 text-center margin-5-rem-bottom wow animate__fadeIn">

                            <h5 className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px">
                                آخرین اخبار
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 blog-content">
                            <LatestBlog count={3}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetail;