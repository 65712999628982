import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ServiceDataProvider} from "./context/ServiceDataContext";
import {RoadMapDataProvider} from "./context/RoadMapDataContext";
import {AudienceDataProvider} from "./context/AudienceDataContext";
import {EventDataProvider} from "./context/EventDataContext";
import 'vazir-font/dist/font-face.css';
import {BlogDataProvider} from "./context/BlogDataContext";
import {FaqDataProvider} from "./context/FaqDataContext";
import {PartnersDataProvider} from "./context/PartnersDataContext";
import {BlogCategoryDataProvider} from "./context/BlogCategoryDataContext";
import {BlogTagsDataProvider} from "./context/BlogTagsDataContext";
import {SliderDataProvider} from "./context/SliderDataContext"
ReactDOM.render(
    <ServiceDataProvider>
        <BlogDataProvider>
            <BlogCategoryDataProvider>
                <BlogTagsDataProvider>
                  <SliderDataProvider>
                        <AudienceDataProvider>
                            <RoadMapDataProvider>
                                <EventDataProvider>
                                    <PartnersDataProvider>
                                        <FaqDataProvider>
                                            <App/>
                                        </FaqDataProvider>
                                    </PartnersDataProvider>
                                </EventDataProvider>
                            </RoadMapDataProvider>
                        </AudienceDataProvider>
                  </SliderDataProvider>
                </BlogTagsDataProvider>
            </BlogCategoryDataProvider>
        </BlogDataProvider>
    </ServiceDataProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();