import axios from "axios";

export function apiGet(url, headers) {
    return axios.get('https://api.bitagrp.com' + url, { ...headers })
}

export function apiPost(url, requestData) {
    return axios.post('https://api.bitagrp.com' + url, requestData);
}

export function apiPut(url, requestData) {
    return axios.put('https://api.bitagrp.com' + url, requestData);
}