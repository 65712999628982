import React from 'react'
import {Link} from "react-router-dom";

const AppHeader = () => {
    const CloseMenu = () => {
        document.querySelector('.navbar-toggler').classList.add('collapsed');
        document.querySelector('.navbar-toggler').setAttribute('aria-expanded', 'false')
        document.querySelector('#navbarNav').classList.remove('show');
        document.querySelector('body').classList.remove('navbar-collapse-show');
    }
    const menuItems = [
        {name: 'صفحه اصلی', link: '/'},
        {name: 'خدمات', link: '/services'},
        // {name: 'اخبار', link: '/blog'},
        // {name: 'رویداد ها', link: '/event'},
        {name: 'سوالات متداول', link: '/faq'},
        {name: 'تماس با ما', link: '/contact'},
        {name: 'درباره ما', link: '/about'},
    ]
    return (
        <header className="header-with-topbar">
            <nav className="navbar navbar-expand-lg top-space navbar-light bg-white header-light fixed-top navbar-boxed ">
                <div className="container nav-header-container p-3 p-md-0">
                    <div className="col-6 col-lg-2 me-auto ps-lg-0">
                        <Link className="navbar-brand" to="/">
                            <img src={'/assets/images/Logo/Logo.png'} data-at2x={'/assets/images/Logo/Logo.png'}
                                 className="default-logo" alt=""/>
                            <img src={'/assets/images/Logo/Logo.png'} data-at2x={'/assets/images/Logo/Logo.png'}
                                 className="alt-logo" alt=""/>
                            <img src={'/assets/images/Logo/Logo.png'}
                                 data-at2x={'/assets/images/Logo/Logo.png'} className="mobile-logo"
                                 alt=""/>
                        </Link>
                    </div>
                    <div className="col-auto menu-order px-lg-0">
                        <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNav" aria-controls="navbarNav" aria-label="Toggle navigation">
                            <span className="navbar-toggler-line"/>
                            <span className="navbar-toggler-line"/>
                            <span className="navbar-toggler-line"/>
                            <span className="navbar-toggler-line"/>
                        </button>
                        <div className=" collapse navbar-collapse justify-content-center" id="navbarNav">
                            <ul className="navbar-nav alt-font">

                                {
                                    menuItems.map((item, i)=>
                                        <li className="nav-item dropdown simple-dropdown" key={i}>
                                            <Link to={item.link} className="nav-link" onClick={CloseMenu}>{item.name}</Link>
                                        </li>
                                    )
                                }


                            </ul>
                        </div>
                    </div>
                    <div className="col-auto text-end pe-0 font-size-0">
                        <div className="header-button d-none d-md-inline-block">
                            <Link to="/consulting"
                                  className="btn btn-small btn-primary btn-joinus">مشاوره
                            </Link>
                        </div>
                        <div className="header-button d-md-none">
                            <Link to="/consulting" className="nav-link btn btn-primary text-white">مشاوره</Link>
                        </div>

                    </div>
                </div>
            </nav>
        </header>
    )
        ;
};

export default AppHeader;
